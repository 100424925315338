/* Small devices (landscape phones, 576px and up) xs*/
@media (max-width: 768px) {
  .btn {
    font-weight: 600;
    font-size: 12px !important;
  }

  h1,
  .h1 {
    font-size: 32px !important;
  }

  h2,
  .h2 {
    font-size: 24px !important;
  }

  h3,
  .h3 {
    font-size: 20px !important;
  }

  h4,
  .h4 {
    font-size: 18px !important;
  }

  p,
  address,
  ul,
  li,
  th,
  td,
  tr {
    font-size: 16px !important;
    letter-spacing: 0.25px;
  }
}

// /* // Medium devices (tablets, 768px and up) sm*/
@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: 36px !important;
  }

  h2,
  .h2 {
    font-size: 28px !important;
  }

  h3,
  .h3 {
    font-size: 24px !important;
  }

  h4,
  .h4 {
    font-size: 20px !important;
  }

  p,
  address,
  ul,
  li,
  th,
  td,
  tr {
    font-size: 16px !important;
    letter-spacing: 0.25px;
  }
}

// /* // Large devices (desktops, 992px and up) md*/
@media (min-width: 992px) {
  h1,
  .h1 {
    font-size: 40px !important;
  }

  h2,
  .h2 {
    font-size: 36px !important;
  }

  h3,
  .h3 {
    font-size: 28px !important;
  }

  h4,
  .h4 {
    font-size: 24px !important;
  }

  p,
  address,
  ul,
  li,
  th,
  td,
  tr {
    font-size: 18px !important;
    letter-spacing: 0.25px;
  }
}

/* // Extra large devices (large desktops, 1200px and up) lg*/
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 56px !important;
  }

  h2,
  .h2 {
    font-size: 36px !important;
  }

  h3,
  .h3 {
    font-size: 28px !important;
  }

  h4,
  .h4 {
    font-size: 24px !important;
  }
}
