// $primary: #f0494f;
// $primary: #007ccf;
$primary: #0179fe;
$secondary: #023e8a;
$light: #f3f3f3;
$lightGrey: #fff;
$grey: #e1e2e2;
$dark: #01161e;

@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

body {
  overflow-x: hidden !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  font-family: "Inter", system-ui;
  // font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

a,
a:active,
a:link,
a:visited {
  color: #000;
  text-decoration: none !important;
  font-family: "Inter", system-ui;
  // font-family: "Roboto", sans-serif;
  font-style: normal;
}

p {
  margin-bottom: 0 !important;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   color: $primary;
// }

// inter
.inter {
  font-family: "Inter", system-ui !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.inter-bold {
  font-family: "Inter", system-ui !important;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

// source-serif-4
.ss4 {
  font-family: "Source Serif 4", serif !important ;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.ss4-bold {
  font-family: "Source Serif 4", serif !important;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

// roboto
.roboto-regular {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.roboto {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif !important;
  font-weight: 700;
  font-style: normal;
}

// FONT STYLES
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Source Serif 4", serif !important;
  font-optical-sizing: auto !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1380px !important;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1520px !important;
  }
}

// @media only screen and (max-width: 600px) {...}
// @media only screen and (min-width: 600px) {...}
// @media only screen and (min-width: 768px) {...}
// @media only screen and (min-width: 992px) {...}
// @media only screen and (min-width: 1200px) {...}

.page-padding {
  padding-top: 80px !important;
}

@media only screen and (min-width: 992px) {
  .page-padding {
    padding-top: 100px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .page-padding {
    padding-top: 120px !important;
  }
}

@media only screen and (min-width: 1400px) {
  .page-padding {
    padding-top: 90px !important;
  }
}

// @media only screen and (min-width:992px) and (max-width: 1400px) {
//   .page-padding{
//     padding-top: 100px !important;
//   }
// }

// SCROLLBARS
.scrollbar-none::-webkit-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.overflowX-scroll {
  overflow-x: "scroll" !important;
}

.overflowY-scroll {
  overflow-y: "scroll" !important;
}

// FLEX WRAP
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-grow {
  flex-grow: 1 !important;
  flex: 1 0 auto !important;
}

// REMOVE INPUT FOCUS

// no focus
.focus-none:focus,
.focus-none:visited {
  // border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: currentColor;
}

// input focus
.form-check-input:focus,
.form-select:focus,
.form-control:focus {
  border-color: none !important;
  box-shadow: none !important;
  outline: none !important;
}

// checkbox accent color
.form-check-input:checked,
.form-check-input::after {
  background-color: $primary !important;
  border-color: none !important;
  box-shadow: none !important;
  outline: none !important;
  box-sizing: unset !important;
}

input[type="checkbox"] {
  accent-color: $primary !important;
}

// FONT COLORS
.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-light {
  color: $light !important;
}

.text-lightGrey {
  color: $lightGrey !important;
}

.text-grey {
  color: $grey !important;
}

.text-dark {
  color: $dark !important;
}

.text-highlight {
  color: #3abef9 !important;
}

// BACKGROUND COLORS
.bg-primary {
  background: $primary !important;
}

.bg-secondary {
  background: $secondary !important;
}

.bg-light {
  background: $light !important;
}

.bg-lightGrey {
  background: $lightGrey !important;
}

.bg-grey {
  background: $grey !important;
}

.bg-dark {
  background: $dark !important;
}

// .border {
//   border-width: 1px !important;
//   border-style: solid !important;
// }

// BORDER COLORS
.border-primary {
  border-color: $primary !important;
}

.border-secondary {
  border-color: $secondary !important;
}

.border-light {
  border-color: $light !important;
}

.border-lightGrey {
  border-color: $lightGrey !important;
}

.border-grey {
  border-color: $grey !important;
}

.border-dark {
  border-color: $dark !important;
}

// FOOTER
// .footer-title-container {
//   border-bottom-width: 60px;
//   border-bottom: 3px solid $primary;
// }

// .title-container {
//   border-bottom-width: 120px;
//   border-bottom: 3px solid $primary;
// }

.contact-us-cta:hover {
  color: $primary !important;
}

.box-shadow {
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
}

.site-btn:hover {
  color: $primary !important;
  background-color: $light !important;
}
